import logo from './img/alkr-logo.png';
import './App.scss';
import './index.css';

function App() {
  return (
    <div className="App w-screen h-full flex flex-wrap content-between">
      <header className="App-header p-8 flex justify-end">
				<div className="md:w-1/3 w-1/2">
        	<img src={logo} alt="ALKR Developments" />
				</div>
      </header>
			<footer className="p-8">
				<p><strong>ALKR Developments Limited</strong></p>
				<p>Registered Office: Oakleigh House, High Street, Hartley Wintney, Hampshire, RG27 8PE</p>
			</footer>
    </div>
  );
}

export default App;
